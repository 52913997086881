import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import * as userService from "../services/userService";
import Container from "react-bootstrap/Container";

class ForgotPassword extends Form {
  state = {
    data: { username: ""},
    sentEmail: false,
    errors: {}
  };

  schema = {
    username: Joi.string()
      .required()
      .email()
      .label("Username"),
  };

  doSubmit = async () => {
    try {
      console.log("in doSubmit forgot");
      await userService.forgotPassword(this.state.data);
      this.setState({sentEmail: true})  
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    if (this.state.sentEmail) {
    return (<span>An email with reset instructions is on its way.</span>)
    }
    else
    {
    return (
      <Container>
        <h1>Forgot Password</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "Enter email address")}
          {this.renderButton("Submit")}
        </form>
      </Container>
    );
    }
  }
}

export default ForgotPassword;
