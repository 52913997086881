import React, { Component } from "react";
import Button from "react-bootstrap/Button";

import ReactHowler from "react-howler";

class Play extends Component {
  state = {
    isPlaying: false,
  };

  handlePlay = () => {
    if (this.state.isPlaying === false) this.setState({ isPlaying: true });
    else this.setState({ isPlaying: true });

    console.log("Button clicked");
  };

  handleFinish = () => {
    console.log("handling finish");
    this.setState({ isPlaying: false });
  };

  render() {
    const { url } = this.props;
    console.log("url", url);
    return (
      <React.Fragment>
        <ReactHowler
          playing={this.state.isPlaying}
          onFinishedPlaying={this.handleFinish}
          preload={true}
          url={url}
          format={["mp3", "aac"]}
        />{" "}
        <Button onClick={this.handlePlay}>
          {this.state.isPlaying === false ? true : false}{" "}
        </Button>
      </React.Fragment>
    );
  }
}

export default Play;
