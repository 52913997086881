import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Table from "./common/table";
import { getTestRecordsForUser } from "../services/testService";
import user from "../services/userService";

class ManageUsers extends Component {
  state = {
    users: "",
    sortColumn: { path: "name", order: "asc" },
  };

  columns = [
    { path: "email", label: "Email" },
    { path: "ntests", label: "# Tests" },
    { path: "ncomplete", label: "# Complete" },
  ];

  handleSort = async (sortColumn) => {
    await this.setState({ sortColumn });
  };

  async componentDidMount() {
    const users = await user.getUsers();
    this.setState({ users: users.data });
    for (let user of users.data) {
      // For each user retrieve tests and count how many are complete
      const tests = await getTestRecordsForUser(user.email);
      console.log("xxx", tests);
      const completeTests =
        tests && tests.filter((t) => t.nComplete === t.nStimuli);
      user.ntests = tests.length;
      user.ncomplete = completeTests.length;
    }
    this.setState({ users: users.data });
  }

  render() {
    const { sortColumn, users } = this.state;

    return (
      <Container>
        <h1>User Progress</h1>
        <Table
          columns={this.columns}
          data={users ? users : []}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
      </Container>
    );
  }
}

export default ManageUsers;
