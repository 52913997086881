import auth from "../services/authService";
import React from "react";
import { useAsync } from "react-async-hook";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getMyTests, getMyTestRecords } from "../services/testService";
import Tutorial from "./tutorialFlow/Tutorial";
import { useState } from "react";

const TestButton = ({ testname, label }) => (
  // Optional button label - defaults to testname
  <Button
    id={testname}
    key={testname}
    className="m-2"
    href={"/runTest/" + testname}
  >
    {label || testname}
  </Button>
);

/*
const ButtonMailto = ({ mailto, label }) => {
  return (
    <Button
      to="#"
      onClick={(e) => {
        window.location = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Button>
  );
};
*/

// TestSelector - was for debugging.
/*
const TestSelector = ({ tests }) => (
  <React.Fragment>
    <h1>Select Test</h1>
    {tests && tests.map((t) => <TestButton test={t} />)}
  </React.Fragment>
);
*/

const TestProgress = ({ nComplete, nRemaining }) => (
  // Show progress through tests
  // Currently using a progress bar but might need redesigning
  // Text on the progress bar is very small

  <React.Fragment>
    <Card>
      <Card.Body>
        <Card.Title>You have {nRemaining} tests left to complete.</Card.Title>
        <Card.Text>
          <ProgressBar
            id="sessionProgressBar"
            variant="success"
            striped
            now={(100 * nComplete) / (nRemaining + nComplete)}
          />
        </Card.Text>
      </Card.Body>
      {nRemaining === 0 && (
        <Card.Footer>You have completed all the tests! Thank you!</Card.Footer>
      )}
    </Card>
  </React.Fragment>
);

function Welcome() {
  const user = auth.getCurrentUser();
  console.log("welcome.jsx", user);
  const asyncData = useAsync(getMyTests, []);
  const asyncData2 = useAsync(getMyTestRecords, []);

  const testRecords = asyncData2.result;
  console.log("testrecords", testRecords);
  var nTests = testRecords && testRecords.length;
  const incompleteTests =
    testRecords && testRecords.filter((t) => t.nComplete < t.nStimuli);
  let nRemaining = 0;

  if (incompleteTests) nRemaining = incompleteTests.length;

  console.log(nRemaining, nTests);
  const [inTutorial, setInTutorial] = useState(false);
  console.log("incomplete", incompleteTests);

  /*var mailto =
    "mailto:claritychallengecontact@gmail.com?subject=Help with Listen@Home - " +
    user.name +
    "&body=Dear ClarityTeam,%0D%0A";
    */
  const display = inTutorial ? (
    // Doing the pre-test checks
    <Tutorial onComplete={() => setInTutorial(false)}></Tutorial>
  ) : (
    <React.Fragment>
      <h1>Welcome</h1>
      <p />
      If this is your first visit, please read the tutorial.
      <p />
      {nTests > 0 && (
        <TestProgress nRemaining={nRemaining} nComplete={nTests - nRemaining} />
      )}
      <p />
      {!asyncData.loading &&
        !asyncData2.loading &&
        incompleteTests.length > 0 && (
          <React.Fragment>
            <Button className="m-2" onClick={() => setInTutorial(true)}>
              Read the tutorial
            </Button>
            <Link to="/contactus" className="btn btn-primary">
              Contact Us
            </Link>
            <TestButton
              testname={incompleteTests[0]["name"]}
              label="Start next test"
            />
          </React.Fragment>
        )}
    </React.Fragment>
  );

  return display;
}

export default Welcome;
