
class AudioPlayback {

    constructor(filename) {
        this.filename = filename;
        this.loaded = false;
        this.ended = false;
    }

    async load() {
        return new Promise((resolve, reject) => {
            const audio = new Audio(this.filename);
            audio.onerror = reject;
            audio.onloadeddata = () => {
                this.loaded = true;
                this.audioElement = audio;
                resolve();
            }

            audio.addEventListener('ended', () => {
                this.ended = true;
            });
        });
    }

    connectAnalyser(audio) {
        if (this.analysed) {
            return;
        }
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        analyser.connect(audioContext.destination);
        const source = audioContext.createMediaElementSource(audio);
        
        audio.addEventListener("canplaythrough", function cb(){
            source.connect(analyser);
            audio.removeEventListener('canplaythrough', cb);
        });

        this.analyser = analyser;
        this.analysed = true;
    }

    getDataFromAudio() {
        const freqByteData = new Uint8Array(this.analyser.fftSize/2);
        const timeByteData = new Uint8Array(this.analyser.fftSize/2);
        this.analyser.getByteFrequencyData(freqByteData);
        this.analyser.getByteTimeDomainData(timeByteData);
        
        return {
            f: freqByteData,
            t: timeByteData
        };
    }


    play() {
        if (!this.loaded) {
            throw Error('Failed to play audio, it has not been loaded yet')
        }

        let audio = this.audioElement;
        audio.volume = 1;
        audio.currentTime = 0;
        this.ended = false;
        this.connectAnalyser(audio);
        audio.play();
    }

    pause() {
        if (!this.loaded) {
            throw Error('Failed to pause audio, it has not been loaded yet')
        }
        this.audioElement.pause();
    }

    async waitForComplete(progressCb=null) {

        if (!progressCb) progressCb = () => {};

        return new Promise((resolve, reject) => {
            if (this.ended) {
                resolve();
                return;
            }

            let element = this.audioElement;
            let self = this;

            function progress(e) {
                progressCb(self.getDataFromAudio());
            }

            const progressI = setInterval(progress, 20);

            element.addEventListener('ended', function complete() {
                element.removeEventListener('complete', complete);
                clearInterval(progressI)
                self.ended = true;
                resolve();
            });

            element.addEventListener('error', function error() {
                element.removeEventListener('error', error);
                clearInterval(progressI);
                reject();
            })
        });
    }

}

const audioCache = new Map();

export async function playAudioFile(filename, cache=true) {

    if (!cache) {
        let audio = new AudioPlayback(filename);
        await audio.load();
        audio.play();
        return audio;
    }

    if (!audioCache.has(filename)) {
        let audio = new AudioPlayback(filename);
        await audio.load();
        audioCache.set(filename, audio);
    }

    let audio = audioCache.get(filename);
    audio.play();
    return audioCache.get(filename);
}