import http from "./httpService";

const apiEndpoint = "/tutorials";


export function getTutorial(tutorialName) {
    console.log("getTutorial");
    var pages = http.get(`${apiEndpoint}/${tutorialName}`);

    return pages;
}