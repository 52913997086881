import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

const Input = ({ name, label, error, ...rest }) => {
  return (
    <Form.Group>
      <Form.Label htmlFor={name}>{label}</Form.Label>
      <Form.Control
        {...rest}
        name={name}
        id={name}
        multiple={"multiple" in rest && rest["multiple"]}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </Form.Group>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default Input;
