import http from "./httpService";

const apiEndpoint = "/messages";


export function send(text) {
    console.log("sendMessage");
    var message = http.post(`${apiEndpoint}/`, {
        message: text
    });

    return message;
}

export default {
    send
};