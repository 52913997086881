import React from "react";
import Button from "react-bootstrap/esm/Button";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";

export const BottomBar = ({
  children,
  success,
  back,
  flowStage,
  flowTotal,
}) => {
  return (
    <>
      <ButtonGroup>
        {flowStage > 0 && (
          <Button variant="secondary" onClick={back}>
            Back
          </Button>
        )}
        <Button onClick={success}>{children}</Button>
      </ButtonGroup>

      {/* Page: ({flowStage+1}/{flowTotal}) */}
    </>
  );
};
