import http from "./httpService";


async function fetchTracks(trackList, filter, setState) {
    const tracks = filter ? filter(trackList)["data"] : trackList;
    if (!tracks) return;

    for (const track of tracks) {

        console.log("fetch Track")
        if (track.metadata.blobURL) // CHECK: Is this necessary
            URL.revokeObjectURL(track.metadata.blobURL)

        const res = await http.get(
            "/recordings/" + track.listName + "/" + track._id, {
                responseType: "arraybuffer",
            }
        );
        var blob = new Blob([res.data], {
            type: "audio/mp3"
        });
        track.metadata.blobURL = URL.createObjectURL(blob);

        setState({
            recordings: trackList
        });
    }
}

async function deleteTrack(track) {
    console.log(track);
    return await http.delete("/recordings/" + track.listName + "/" + track._id);
}

async function fetchTrackList(listName, owner = "") {
    let {
        data: trackList
    } = await http.get("/recordings/" + listName);
    for (const track of trackList) {
        track.listName = listName;
    }
    console.log("owner", owner);
    if (owner)
        trackList = trackList.filter(r => r.metadata.owner === owner.email);
    return trackList;
}

function saveTrack(trackListName, owner, filename, file) {
    let bodyFormData = new FormData();
    bodyFormData.set("bucketname", trackListName);

    bodyFormData.append("owner", owner ? owner.email : "Anonymous");
    bodyFormData.append("name", filename);
    bodyFormData.append("audio", file);
    http.post("/recordings", bodyFormData);
}

export default {
    fetchTracks,
    deleteTrack,
    fetchTrackList,
    saveTrack,
};