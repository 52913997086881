import React, { Component } from "react";
import auth from "../services/authService";

import Upload from "./common/upload/upload/Upload";

class UploadStimuli extends Component {
  doUpload(req, file) {
    console.log("here in upload");
    const user = auth.getCurrentUser();
    const username = user ? user.email : "Anonymous";
    let bodyFormData = new FormData();
    bodyFormData.set("bucketname", "stimuli");
    bodyFormData.append("owner", username);
    bodyFormData.append("name", file.name);
    bodyFormData.append("audio", file);

    console.log("file", file);
    req.open("POST", process.env.REACT_APP_API_URL + "/recordings");
    req.send(bodyFormData);
  }

  render() {
    return <Upload doUpload={this.doUpload} title="Upload Audio Files" />;
  }
}

export default UploadStimuli;
