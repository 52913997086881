import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Container from "react-bootstrap/Container";

import "./App.css";
import About from "./components/about";
import Home from "./components/home";
import ListeningTest from "./components/listeningTest";
import LoginForm from "./components/loginForm";
import Logout from "./components/logout";
import ForgotPassword from "./components/forgotPassword";
import ResetPassword from "./components/resetPassword";
import ContactForm from "./components/ContactForm";

import ManageUsers from "./components/manageUsers.jsx";
import NavBar from "./components/navBar";
import ProtectedRoute from "./components/protectedRoute";
import RecordStimuli from "./components/recorder";
import RegisterForm from "./components/registerForm";
import PretestChecks from "./components/pretestChecks";
import Welcome from "./components/welcome";

import TestTable from "./components/testTable";

import Tracks from "./components/tracks";

import UploadStimuli from "./components/uploadStimuli";
import UploadTest from "./components/uploadTest";
import auth from "./services/authService";
import "react-toastify/dist/ReactToastify.css";

class App extends React.Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({
      user,
    });
  }

  render() {
    const { user } = this.state;

    // Regular users have large fonts and centered style
    var fontsize = "1.6rem";
    var topstyle = "mycenter";

    // Admin user is styled differently
    if (user && user.isAdmin) {
      fontsize = "1.0rem";
      topstyle = "";
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <NavBar user={user} />{" "}
        <Container style={{ fontSize: { fontsize } }} className={topstyle}>
          <Switch>
            <Route path="/about" component={About} />{" "}
            <ProtectedRoute
              path="/runTest/:testName"
              component={ListeningTest}
            />{" "}
            <ProtectedRoute path="/runTest" component={PretestChecks} />{" "}
            <ProtectedRoute path="/welcome" component={Welcome} />{" "}
            <ProtectedRoute path="/contactus" component={ContactForm} />{" "}
            <ProtectedRoute path="/record" component={RecordStimuli} />{" "}
            <ProtectedRoute path="/review/tests" component={TestTable} />{" "}
            <ProtectedRoute path="/review/:listName" component={Tracks} />{" "}
            <ProtectedRoute path="/upload" component={UploadStimuli} />{" "}
            <ProtectedRoute path="/uploadTest" component={UploadTest} />{" "}
            <ProtectedRoute path="/users" component={ManageUsers} />{" "}
            <Route path="/register" component={RegisterForm} />{" "}
            <Route path="/login" component={LoginForm} />{" "}
            <Route path="/logout" component={Logout} />{" "}
            <Route path="/forgot" component={ForgotPassword} />{" "}
            <Route path="/reset/:id" component={ResetPassword} />{" "}
            <Route path="/" component={Home} />
            <Redirect to="/not-found" />
          </Switch>{" "}
        </Container>
      </React.Fragment>
    );
  }
}

export default App;
