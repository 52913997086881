import React from "react";

//import SetVolume from "./setVolume";
import SetupFlow, {
  LevelCheck,
  MicrophoneCheck,
  HeadphoneCheck,
  Welcome,
  Finish,
} from "./setupFlow";

const PretestChecks = ({ onComplete, levelCheckAudio }) => (
  <SetupFlow completedAudio="/audio/ding.mp3" onComplete={onComplete}>
    <Welcome></Welcome>
    {/* <Volume></SetVolume> */}
    <LevelCheck audio={levelCheckAudio}></LevelCheck>

    <HeadphoneCheck
      gapBetween={2000}
      leftAudio="/audio/audiocheck.net_L.mp3"
      rightAudio="/audio/audiocheck.net_R.mp3"
    ></HeadphoneCheck>

    {/* <LevelCheck audio="/s10_l_pbwz2n.wav" playEvery={4000}></LevelCheck> */}

    <MicrophoneCheck
      recordingLength={4000}
      audio="/audio/tone.mp3"
      timeBeforeBeep={1000}
    ></MicrophoneCheck>

    <Finish></Finish>
  </SetupFlow>
);

export default PretestChecks;
