import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import * as userService from "../services/userService";
import Container from "react-bootstrap/Container";

class ResetForm extends Form {
  state = {
    data: {password: ""},
    errors: {}
  };

  schema = {
    password: Joi.string()
      .required()
      .min(5)
      .label("Password"),
  };

  doSubmit = async () => {
    try {
      console.log("in doSubmit reset");
      await userService.resetPassword(this.state.data.password, this.props.match.params.id);
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <Container>
        <h1>Reset Password</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("password", "Password", "password")}
          {this.renderButton("Submit")}
        </form>
      </Container>
    );
  }
}

export default ResetForm;
