import { useEffect, useCallback, useRef, useState } from 'react';

// https://javascript.plainenglish.io/usetimeout-react-hook-3cc58b94af1f

export const useTimeout = (callback, timeout=0) => {
    const timeoutIdRef = useRef();
    const cancel = useCallback(() => {
        const timeoutId = timeoutIdRef.current;
        if (timeoutId) {
            timeoutIdRef.current = undefined;
            clearTimeout(timeoutId);
        }
    }, [timeoutIdRef]);
    
    useEffect(() => {
        timeoutIdRef.current = setTimeout(callback, timeout);
        return cancel;
    }, [callback, timeout, cancel]);
    
    return cancel;
};


export const useElapsedTime = (fps) => {
    const [time, setTime] = useState(0);
    const startTimeRef = useRef(Date.now());

    useInterval(() => {
        setTime(Date.now() - startTimeRef.current)
    }, fps);

    return time;
}


export const useInterval = (callback, interval=0) => {
    const intervalIdRef = useRef();
    const cancel = useCallback(() => {
        const intervalId = intervalIdRef.current;
        if (intervalId) {
            intervalIdRef.current = undefined;
            clearInterval(intervalId);
        }
    }, [intervalIdRef]);
    
    useEffect(() => {
        intervalIdRef.current = setInterval(callback, interval);
        return cancel;
    }, [callback, interval, cancel]);
    
    return cancel;
};