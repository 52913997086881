import React from "react";
import {
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  ButtonToolbar,
} from "react-bootstrap";
import message from "../services/messageService";

class ContactForm extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value: "",
    };
  }

  getValidationState() {
    const length = this.state.value.length;
    if (length > 10) return "success";
    if (length > 5) return "warning";
    if (length > 0) return "error";
    return null;
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  handleCancel(e) {
    console.log("Clicked cancel");
    window.location = "/welcome";
  }

  async handleSubmit(e) {
    console.log("Clicked submit", this.state.value);
    await message.send(this.state.value);
    window.location = "/welcome";
  }

  render() {
    return (
      <React.Fragment>
        <h2>Contact Us</h2>
        If you are having any problems, contact us using the form below. We will
        get back to you as soon as possible.
        <p />
        <form>
          <FormGroup controlId="formBasicText">
            <FormLabel>
              Type your message and press &lsquo;Send&rsquo;. Press
              &lsquo;Back&rsquo; to cancel.
            </FormLabel>
            <FormControl
              as="textarea"
              rows="9"
              size="lg"
              type="text"
              value={this.state.value}
              placeholder="Enter your message"
              onChange={this.handleChange}
            />
          </FormGroup>
          <ButtonToolbar className="justify-content-between">
            <Button
              value="Cancel"
              onClick={() => {
                this.handleCancel();
              }}
            >
              {" "}
              Back
            </Button>{" "}
            <Button
              value="Send"
              disabled={!this.getValidationState()}
              onClick={() => {
                this.handleSubmit();
              }}
            >
              {" "}
              Send
            </Button>
          </ButtonToolbar>
        </form>
      </React.Fragment>
    );
  }
}

export default ContactForm;
