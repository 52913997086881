import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";

import Play from "./common/play";

import Table from "./common/table";
import _ from "lodash";
import Pagination from "./common/pagination";

import auth from "../services/authService";
import audio from "../services/audioService";
import { paginate } from "../utils/paginate";

class Tracks extends Component {
  state = {
    recordings: "",
    user: "",
    sortColumn: { path: "name", order: "asc" },
    currentPage: 1,
    pageSize: 6,
  };

  columns = [
    {
      path: "filename",
      label: "name",
    },
    { path: "uploadDate", label: "uploadDate" },
    { path: "metadata.owner", label: "owner" },
    {
      key: "play_button",
      label: "",
      content: (data) =>
        data.metadata.blobURL ? <Play url={data.metadata.blobURL}></Play> : " ",
    },
  ];

  deleteColumn = {
    key: "delete",
    content: (track) => (
      <Button
        variant="danger"
        onClick={() => {
          this.handleDelete(track);
        }}
      >
        Delete
      </Button>
    ),
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();

    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  async componentDidMount() {
    const listName = this.props.match.params.listName;
    const user = auth.getCurrentUser();
    this.setState({ user });
    const trackList = await audio.fetchTrackList(listName);
    this.setState({ recordings: trackList });
    audio.fetchTracks(trackList, this.getPageData, (r) => {
      this.setState(r);
    });
  }

  handlePageChange = async (page) => {
    await this.setState({ currentPage: page });
    audio.fetchTracks(this.state.recordings, this.getPageData, (r) => {
      this.setState(r);
    });
  };

  handleSort = async (sortColumn) => {
    await this.setState({ sortColumn });
    audio.fetchTracks(this.state.recordings, this.getPageData, (r) => {
      this.setState(r);
    });
  };

  handleDelete = async (track) => {
    console.log("Delete track");
    const originalTracks = this.state.recordings;
    const tracks = originalTracks.filter((m) => m._id !== track._id);
    this.setState({ recordings: tracks });
    try {
      await audio.deleteTrack(track);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This track has already been deleted.");
      this.setState({ recordings: originalTracks });
      // throw ex;
    }
    // The below is needed as new tracks will come into view...
    audio.fetchTracks(this.state.recordings, this.getPageData, (r) => {
      this.setState(r);
    });
  };

  getPageData = (allData) => {
    const { sortColumn, user, currentPage, pageSize } = this.state;
    if (!allData) return { totalCount: 0, data: null };
    const filtered = user.isAdmin
      ? allData
      : allData.filter((r) => r.metadata.owner === user.email);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const page = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: page };
  };

  render() {
    const { recordings, sortColumn, pageSize, currentPage } = this.state;

    const { totalCount, data: recording_list } = this.getPageData(recordings);
    return (
      <Container>
        <h1>{this.props.match.params.listName}</h1>
        <Table
          columns={this.columns}
          data={recording_list ? recording_list : []}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </Container>
    );
  }
}

export default Tracks;
