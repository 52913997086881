import React, { useState } from "react";
import { useTimeout } from "../hooks";
// import { playAudioFile } from '../audio';
import { BottomBar } from "../helpers";
import { useVolume } from "../../hooks/useVolume";
import authService from "../../../services/authService";
import ReactHowler from "react-howler";

import Alert from "react-bootstrap/Alert";

const LeftRightIndicator = (props) => {
  var { active } = props;
  var text = active === "right" ? "----- RIGHT" : " LEFT -----";
  return (
    <center>
      <Alert variant="warning">
        <h1>{text}</h1>
      </Alert>
    </center>
  );
};

export const HeadphoneCheck = (props) => {
  const { leftAudio, rightAudio, gapBetween } = props;
  const [volume] = useVolume(authService.getCurrentUser());

  // 'left', 'right'
  const [active, setActive] = useState("left");
  console.log(active);

  useTimeout(() => {
    active === "right" ? setActive("left") : setActive("right");
  }, gapBetween);

  return (
    <div>
      <h1>Are your headphones on correctly?</h1>
      The microphone should be on your left side.
      <p /> You should hear the words `&ldquo;left channel&rdquo; in your left
      ear and `&ldquo;right channel&rdquo; in your right ear.
      <p />
      <LeftRightIndicator active={active}></LeftRightIndicator>
      <p />
      If your headphones are on the wrong way around, please fix this now.
      <p />
      When you are ready, press the button below to proceed.
      <p />
      {active === "left" && (
        <ReactHowler
          volume={volume / 100}
          preload={true}
          loop={false}
          src={leftAudio}
        />
      )}
      {active === "right" && (
        <ReactHowler
          volume={volume / 100}
          preload={true}
          loop={false}
          src={rightAudio}
        />
      )}
      <BottomBar {...props}>My headphones are correct</BottomBar>
    </div>
  );
};
