import React, { Component } from "react";

import Upload from "./common/upload/upload/Upload";

class UploadTest extends Component {
  doUpload(req, file) {
    const fr = new FileReader();

    fr.addEventListener("load", e => {
      const data = JSON.parse(fr.result);
      req.open("POST", process.env.REACT_APP_API_URL + "/tests");
      req.setRequestHeader("Content-type", "application/json");
      req.send(JSON.stringify(data));
    });

    fr.readAsText(file);
  }

  render() {
    return (
      <Upload
        doUpload={this.doUpload}
        title="Upload JSON test description files"
      />
    );
  }
}

export default UploadTest;
