import React from "react";
import Button from "react-bootstrap/esm/Button";

export const Finish = ({ next }) => {
  return (
    <>
      <h1>You&apos;re ready to start</h1>
      <p>Everything looks good and you can now start the experiment.</p>
      <Button onClick={next}>Proceed to experiment</Button>
    </>
  );
};

Finish.skipCount = true;
