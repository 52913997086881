import * as Sentry from "@sentry/browser";

function init() {
  Sentry.init({
    dsn: "https://8e9636a2d8334cccb4ceda42a7d5b737@sentry.io/3260844",
  });
}

function log(error) {
  // Sentry.captureException(error);
  console.log(error);
}

function message(msg) {
  // Sentry.captureMessage(msg);
  console.log(msg);
}

export default {
  init,
  message,
  log,
};
