import React from "react";
import auth from "../../../services/authService";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import RangeSlider from "react-bootstrap-range-slider";

import Container from "react-bootstrap/Container";
import { useVolume } from "../../hooks/useVolume";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import ReactHowler from "react-howler";

export const LevelCheck = (props) => {
  // This is a custom hook that remembers the user's volume setting. If setVolume is called the updated version
  // will be saved. "useVolume" can be called from any component
  const [volume, setVolume] = useVolume(auth.getCurrentUser());

  const acceptVolume = () => {
    // Callback I made for progressing to next step in setup tasks
    props.success(); // options: success(), back(), next()
  };
  console.log("here", props.audio);
  return (
    <div>
      <h1>Adjusting the volume</h1>
      If necessary, use the controls to set the volume to a comfortable level.
      <p>The current level is</p>
      <h2>{volume}</h2>
      <Container>
        <Row>
          <RangeSlider
            id="volumeSlider"
            value={volume}
            step={1}
            max={100}
            min={0}
            tooltip="on" // show, hide or always
            onChange={(changeEvent) => setVolume(changeEvent.target.value)}
            orientation="horizontal"
          />
        </Row>
        <p />
        <Row>
          <Button variant="secondary" onClick={props.back}>
            Back
          </Button>
          <Button variant="primary" onClick={acceptVolume}>
            Accept
          </Button>
        </Row>
      </Container>
      <ReactHowler
        volume={volume / 100}
        preload={true}
        loop={true}
        format={["mp3", "aac"]}
        src={props.audio}
      />
    </div>
  );
};
