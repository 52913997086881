import React from "react";
import Button from "react-bootstrap/Button";

export const Welcome = ({ next }) => {
  return (
    <React.Fragment>
      <h2>Get ready to start</h2>
      Before you start please check the following.
      <p></p>
      <ul>
        <li>Are you in a quiet place with no distractions? </li>
        <li>Is the tablet charged? </li>
        <li>Is the tablet&apos;s volume at the maximum level? </li>
        <li>Are the headphones plugged in? </li>
      </ul>
      When you are ready press the button below.
      <p />
      <Button onClick={next}>Begin the tests</Button>
    </React.Fragment>
  );
};

Welcome.skipCount = true;
