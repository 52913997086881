import http from "./httpService";

const apiEndpoint = "/users";

export function register(user) {
    return http.post(apiEndpoint + '/signup', {
        email: user.username,
        password: user.password,
        name: user.name
    });
}

export function forgotPassword(user) {
    return http.post(apiEndpoint + '/forgot', {
        email: user.username,
    });
}

export function getUsers() {
    console.log('in getUsers')
    return http.get(apiEndpoint + '/users');
}

export function resetPassword(password, id) {
    return http.patch(apiEndpoint + '/reset', {
        password: password,
        id: id
    });
}

export default {
    getUsers
};