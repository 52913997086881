import { useState, useEffect } from "react";
import React from "react";
import { playAudioFile } from "./audio";

// So the components can be imported all at once by the consumer.
export { LevelCheck } from "./steps/LevelCheck";
export { HeadphoneCheck } from "./steps/HeadphoneCheck";
export { MicrophoneCheck } from "./steps/MicrophoneCheck";
export { Welcome } from "./steps/Welcome";
export { Finish } from "./steps/Finish";

/**
 *
 * const handleComplete = () => {
 *     this.state.inSetup = false;
 * }
 *
 * {{ inSetup &&
 * <SetupFlow onComplete={handleComplete}>
 *  <HeadphoneCheck audio={}/>
 *  <LevelCheck audio={}/>
 *  <MicrophoneCheck audio={}/>
 *  <RecordingQualityCheck />
 * </SetupFlow> }}
 *
 *
 */

const SetupFlow = ({ onComplete, children, completedAudio }) => {
  const [stage, setStage] = useState(0);
  const [skips, setSkips] = useState(0);
  const childrenArray = React.Children.toArray(children);
  let numStages = children ? children.length : 0;

  useEffect(() => {
    if (stage >= numStages) {
      onComplete();
      return;
    }
  }, [stage, numStages, onComplete]);

  useEffect(() => {
    let count = 0;
    children.map((c) => (count += !!c.type.skipCount));
    setSkips(count);
  }, [children]);

  if (stage >= numStages) {
    return null;
  }

  return React.cloneElement(childrenArray[stage], {
    success: async () => {
      playAudioFile(completedAudio);
      setStage(stage + 1);
    },
    next: () => {
      setStage(stage + 1);
    },
    back: () => {
      setStage(stage - 1);
    },
    flowTotal: childrenArray.length - skips,
    skipCount: () => {
      setSkips(skips + 1);
    },
    flowStage: stage - skips,
  });
};

export default SetupFlow;
