import React, { Component } from "react";

import { getTutorial } from "../../services/tutorialService";

import { Page } from ".";

import SetupFlow from "../setupFlow";

class Tutorial extends Component {
  state = {
    pages: "",
  };

  onComplete = this.props.onComplete;

  async componentDidMount() {
    var res = await getTutorial("main");
    this.setState({ pages: res.data.pages });
  }

  render() {
    var pages = this.state.pages;
    var content;

    if (pages === "") content = <div></div>;
    else {
      pages = pages.map((page, index) => {
        return { __html: page, key: index };
      });

      content = (
        <SetupFlow
          completedAudio="/audio/click.wav"
          onComplete={this.onComplete}
        >
          {pages.map((page, index) => {
            return <Page key={index} content={page}></Page>;
          })}
        </SetupFlow>
      );
    }
    return <div>{content}</div>;
  }
}
export default Tutorial;
